exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-carbon-optimisation-tsx": () => import("./../../../src/pages/services/carbon-optimisation.tsx" /* webpackChunkName: "component---src-pages-services-carbon-optimisation-tsx" */),
  "component---src-pages-services-ccas-pps-tsx": () => import("./../../../src/pages/services/ccas-pps.tsx" /* webpackChunkName: "component---src-pages-services-ccas-pps-tsx" */),
  "component---src-pages-services-ccas-tsx": () => import("./../../../src/pages/services/ccas.tsx" /* webpackChunkName: "component---src-pages-services-ccas-tsx" */),
  "component---src-pages-services-maturity-model-tsx": () => import("./../../../src/pages/services/maturity-model.tsx" /* webpackChunkName: "component---src-pages-services-maturity-model-tsx" */),
  "component---src-pages-services-qms-tsx": () => import("./../../../src/pages/services/qms.tsx" /* webpackChunkName: "component---src-pages-services-qms-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

